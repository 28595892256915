import './App.scss'

const entities = {
  dtxo: {
    name: 'DTXO',
    imprint: 'impd.svg',
  },
  nxef: {
    name: 'NXEF',
    imprint: 'impn.svg',
  },
}

// Defualt entity is nxef
const thisEntity =
  (window.location.host.match(/dtxo/) && entities.dtxo) || entities.nxef

function App() {
  return (
    <div>
      <video className='fixed w-100 h-100' autoplay muted loop id='myVideo'>
        <source src='rain.mp4' type='video/mp4' />
      </video>
      <div className='fixed w-100 h-100'>
        <div className='fixed w-100 h-100 flex flex-column justify-center-ns items-center-ns pl3 pv5'>
          <div className='flex' style={{ height: '4.9rem' }}>
            <svg
              viewBox='0 0 260 93'
              className='do-font'
              style={{ width: '12rem' }}
            >
              <symbol id='s-text'>
                <text
                  dominantBaseline='hanging'
                  textAnchor='start'
                  x='0'
                  y='0'
                  className='f-headline do-font'
                >
                  {thisEntity.name}
                </text>
              </symbol>
              <use className='text' xlinkHref='#s-text'></use>
              <use className='text' xlinkHref='#s-text'></use>
              <use className='text' xlinkHref='#s-text'></use>
              <use className='text' xlinkHref='#s-text'></use>
              <use className='text' xlinkHref='#s-text'></use>
            </svg>
            <div
              className='i fw5'
              style={{
                color: 'hsla(218, 17%, 45%, 1)',
                marginTop: '-0.2rem',
                letterSpacing: '0.01rem',
                textIndent: '0.15rem',
                fontSize: '0.8rem',
              }}
            >
              CORPORATE FINANCE,
              <br />
              STRATEGY & TECH
            </div>
          </div>
        </div>
        {window.location.host.match(/dtxo/) && (
          <div className='fixed w-100 h-100 flex justify-end items-end'>
            <img
              src={thisEntity.imprint}
              className='mr2-ns mr1 mv5-ns mv5'
            />
          </div>
        )}
        {!window.location.host.match(/dtxo/) && (
          <div className='fixed w-100 h-100 flex justify-end justify-center-ns items-end'>
            <div
              className='f7 pa3-ns pr3 pl5 pb4 fw7 tj'
              style={{
                color: 'hsla(218, 17%, 65%, 1)',
              }}
            >
              NXEF GmbH, Corporate Finance, Strategy & Tech, DISCLAIMER:{' '}
              <span className='fw4'>
                We do not offer banking nor financial services according to
                §1 KWG requiring regulatory supervision by banking
                authorities or BaFin. All information is provided merely for
                informational purposes. We do not provide investment advice.{' '}
                <span className='fw7'>CONTACT:</span>
                {' '.concat('info', '@', 'nxef', '.com')}{' '}
                <span className='fw7'> IMPRINT:</span> NXEF GmbH, Anklamer
                Str. 22, D-10115 Berlin, Tel.: +49 (0) 30 60949625,
                Amtsgericht Berlin-Charlottenburg, HRB 186070 B, UStId: DE
                815905292, Legal Entity Identifier:{' '}
                <a
                  href='https://lei.bloomberg.com/leis/view/254900NDZRU8W20M5526'
                  style={{
                    color: 'hsla(218, 17%, 65%, 1)',
                  }}
                >
                  2549 00ND ZRU8 W20M 5526
                </a>
                , Managing Director: Dipl.-Kfm. Ramin G. Far
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default App
